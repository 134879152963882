
export const STEP_LIST = [
  '微信支付商户申请提交入口--中微信通',
  '微信支付商户主体信息填写',
  '微信支付商户法人信息填写',
  '微信支付商户结算银行信息填写',
  '微信支付商户超级管理员及联系方式'
];
export const SUBJECT_TYPE_LIST = {
  SUBJECT_TYPE_INDIVIDUAL: "SUBJECT_TYPE_INDIVIDUAL",
  SUBJECT_TYPE_ENTERPRISE: "SUBJECT_TYPE_ENTERPRISE",
  SUBJECT_TYPE_INSTITUTIONS: "SUBJECT_TYPE_INSTITUTIONS",
  SUBJECT_TYPE_OTHERS: "SUBJECT_TYPE_OTHERS"
};

export const SUBJECT_TYPE_MAP = { // 主体类型映射表
  SUBJECT_TYPE_INDIVIDUAL: "个体户", // 营业执照上的主体类型一般为个体户、个体工商户、个体经营
  SUBJECT_TYPE_ENTERPRISE: "企业", // 营业执照上的主题类型一般为有限公司、有限责任公司；
  SUBJECT_TYPE_INSTITUTIONS: "党政、机关及事业单位", // 包括国内各级、各类政府机构、事业单位等（如： 公安、党团、司法、交通、旅游、工商税务、市政、医疗、教育、学校等机构）
  SUBJECT_TYPE_OTHERS: "其他组织" // 不属于企业、政府/事业单位的组织机构（如社会团体、民办非企业、基金会），要求机构已办理组织机构代码证。
};

export const QUALIFICATION_TYPE_MAP = { // 所属行业
  SUBJECT_TYPE_INDIVIDUAL: [
    {
      text: "餐饮/零售",
      children: [
        {
          text: "食品生鲜",
          value: 719,
          message: "该类目费率0.6%，入账周期T+1",
          qulification_message: "1.销售食用初级农产品：无需特殊资质 2.销售加工非食用农产品需提供与主体一致的：《食品流通许可证》或《食品卫生许可证》或《食品经营许可证》或《保健食品经营卫生许可证》（四选一） 3、提供与主体不一致的以上证件+食品供销协议（至少需包含首页、供销模式描述页、公章页）"
        },
        {
          text: "餐饮",
          value: 719,
          message: "该类目费率0.6%，入账周期T+1",
          qulification_message: "《餐饮服务许可证》/三张照片（门头照、店内环境照、收银台照），若商户照片已经包含上述三个元素，少于三张照片亦可；"
        }
      ]
    },
    {
      text: "交通/加油",
      children: [
        {
          text: "物流/快递",
          value: 719,
          message: "该类目费率0.6%，入账周期T+1",
          qulification_message: "物流提供《道路运输许可证》；快递提供《快递业务经营许可证》"
        },
        {
          text: "机票/机票代理",
          value: 719,
          message: "该类目费率0.6%，入账周期T+1",
          qulification_message: "《航空公司营业执照》或《航空公司机票代理资格证》"
        },
        {
          text: "铁路客运",
          value: 719,
          message: "该类目费率0.6%，入账周期T+1",
          qulification_message: "收费授权证明文件（如授权证明书或合同）"
        },
        {
          text: "加油",
          value: 721,
          message: "该类目费率0.3%，入账周期T+1",
          qulification_message: "《成品油批发经营批准证书》或《成品油仓储经营批准证书》或《成品油零售经营批准证书》，其中一个即可"
        }
      ]
    },
    {
      text: "生活/娱乐",
      children: [
        {
          text: "演出赛事",
          value: 719,
          message: "该类目费率0.6%，入账周期T+1",
          qulification_message: "《营业性演出许可证》"
        },
        {
          text: "院线影城",
          value: 719,
          message: "该类目费率0.6%，入账周期T+1",
          qulification_message: "《电影放映经营许可证》"
        },
        {
          text: "游艺厅/KTV/网吧",
          value: 719,
          message: "该类目费率0.6%，入账周期T+1",
          qulification_message: "游艺厅/KTV提供《娱乐场所许可证》；网吧提供《网络文化经营许可证》"
        },
        {
          text: "共享服务",
          value: 719,
          message: "该类目费率0.6%，入账周期T+1",
          qulification_message: "需提供资金监管协议。协议要求：1、主体与商业银行签订 2、内容针对交易资金使用和偿付进行监管 3、协议须在有效期内 4、结算账户须与资金监管账户一致"
        }
      ]
    },
    {
      text: "互联网服务",
      children: [
        {
          text: "游戏",
          value: 746,
          message: "该类目费率1%，入账周期T+7，单笔限额3K",
          qulification_message: "棋牌类、捕鱼类游戏提供《网络文化经营许可证》，其他游戏请提供营业执照"
        }
      ]
    },
    {
      text: "民生缴费",
      children: [
        {
          text: "其他生活缴费",
          value: 719,
          message: "该类目费率0.6%，入账周期T+1",
          qulification_message: "收费授权证明文件（如授权证明书或合同）"
        },
        {
          text: "话费通讯缴费",
          value: 720,
          message: "该类目费率0.6%，入账周期T+7，单笔限额3K",
          qulification_message: "提供与运营商间的合作授权收费协议"
        }
      ]
    },
    {
      text: "医疗",
      children: [
        {
          text: "民营医院/诊所/卫生站/卫生服务中心",
          value: 719,
          message: "该类目费率0.6%，入账周期T+1",
          qulification_message: "《医疗机构执业许可证》"
        },
        {
          text: "保健器械/医疗器械/非处方药品",
          value: 719,
          message: "该类目费率0.6%，入账周期T+1",
          qulification_message: "互联网售药：提供《互联网药品交易服务证》 线下门店卖药：提供《药品经营许可证》 医疗器械：《医疗器械经营企业许可证》"
        },
        {
          text: "宠物医院",
          value: 719,
          message: "该类目费率0.6%，入账周期T+1",
          qulification_message: "《动物诊疗许可证》"
        }
      ]
    },
    {
      text: "教育",
      children: [
        {
          text: "教育/培训/考试缴费/学费",
          value: 719,
          message: "该类目费率0.6%，入账周期T+1",
          qulification_message: "教育部门的批文或办学许可证"
        }
      ]
    }
  ],
  SUBJECT_TYPE_ENTERPRISE: [
    {
      text: "餐饮/零售",
      children: [
        {
          text: "食品生鲜",
          value: 716,
          message: "该类目费率0.6%，入账周期T+1",
          qulification_message: "1.销售食用初级农产品：无需特殊资质 2.销售加工非食用农产品需提供与主体一致的：《食品流通许可证》或《食品卫生许可证》或《食品经营许可证》或《保健食品经营卫生许可证》（四选一）3、提供与主体不一致的以上证件+食品供销协议（至少需包含首页、供销模式描述页、公章页）"
        },
        {
          text: "文物经营/文物复制品销售",
          value: 716,
          message: "该类目费率0.6%，入账周期T+1",
          qulification_message: "销售文物，需提供《文物经营许可证》 销售文物复制品：无需特殊资质许可"
        },
        {
          text: "餐饮",
          value: 716,
          message: "该类目费率0.6%，入账周期T+1",
          qulification_message: "《餐饮服务许可证》/三张照片（门头照、店内环境照、收银台照），若商户照片已经包含上述三个元素，少于三张照片亦可；"
        }
      ]
    },
    {
      text: "交通/加油",
      children: [
        {
          text: "物流/快递",
          value: 717,
          message: "该类目费率0.3%，入账周期T+1",
          qulification_message: "物流提供《道路运输许可证》；快递提供《快递业务经营许可证》"
        },
        {
          text: "机票/机票代理",
          value: 716,
          message: "该类目费率0.6%，入账周期T+1",
          qulification_message: "《航空公司营业执照》或《航空公司机票代理资格证》"
        },
        {
          text: "船舶/海运服务",
          value: 716,
          message: "该类目费率0.6%，入账周期T+1",
          qulification_message: "请提供《港口经营许可证》"
        },
        {
          text: "城市公共交通",
          value: 716,
          message: "该类目费率0.6%，入账周期T+1",
          qulification_message: "收费授权证明文件（如授权证明数或合同）"
        },
        {
          text: "高速公路收费",
          value: 716,
          message: "该类目费率0.6%，入账周期T+1",
          qulication_message: "收费授权文件（如授权证明书或合同）"
        },
        {
          text: "铁路客运",
          value: 716,
          message: "该类目费率0.6%，入账周期T+1",
          qulification_message: "收费授权文件（如授权证明书或合同）"
        },
        {
          text: "加油",
          value: 717,
          message: "该类目费率0.3%，入账周期T+1",
          qulication_message: "《成品油批发经营批准证书》或《成品油仓储经营批准证书》或《成品油零售经营批准证书》，其中一个即可"
        }
      ]
    },
    {
      text: "生活/娱乐",
      children: [
        {
          text: "旅行社",
          value: 716,
          message: "该类目费率0.6%，入账周期T+1",
          qulication_message: "《旅行社业务经营许可证》"
        },
        {
          text: "演出赛事",
          value: 716,
          message: "该类目费率0.6%，入账周期T+1",
          qulication_message: "《营业性演出许可证》"
        },
        {
          text: "院线影城",
          value: 716,
          message: "该类目费率0.6%，入账周期T+1",
          qulication_message: "《电影放映经营许可证》"
        },
        {
          text: "游艺厅/KTV/网吧",
          value: 716,
          message: "该类目费率0.6%，入账周期T+1",
          qulication_message: "游艺厅/KTV：《娱乐场所许可证》 网吧：《网络文化经营许可证》"
        },
        {
          text: "共享服务",
          value: 716,
          message: "该类目费率0.6%，入账周期T+1",
          qulication_message: "需提供资金监管协议。协议要求：1、主体与商业银行签订 2、内容针对交易资金使用和偿付进行监管 3、协议须在有效期内 4、结算账户须与资金监管账户一致"
        }
      ]
    },
    {
      text: "互联网服务",
      children: [
        {
          text: "众筹",
          value: 714,
          message: "该类目费率0.6%，入账周期T+3",
          qulication_message: "仅限实物类、公益类众筹网站接入申请，暂不支持股权类众筹商户，公益类众筹商户需要提供公募资质"
        },
        {
          text: "在线图书/视频/音乐/网络直播",
          value: 711,
          message: "该类目费率1%，入账周期T+7，单笔限额3K",
          qulication_message: "在线图书/视频/音乐：《互联网出版许可证》或《网络文化经营许可证》或《信息网络传播视听节目许可证》； 网络直播： 1、需提供《网络文化经营许可证》，且许可证的经营范围应当明确包括网络表演 2、PC/wap网站需要有ICP备案"
        },
        {
          text: "游戏",
          value: 711,
          message: "该类目费率1%，入账周期T+7，单笔限额3K",
          qulication_message: "棋牌类、捕鱼类游戏提供《网络文化经营许可证》，其他游戏请提供营业执照"
        }
      ]
    },
    {
      text: "房产/金融",
      children: [
        {
          text: "房地产/房产中介",
          value: 716,
          message: "该类目费率0.6%，入账周期T+1",
          qulication_message: "房地产开发商提供以下五个资质：《建设用地规划许可证》《建设工程规划许可证》《建筑工程开工许可证》《国有土地使用证》《商品房预售许可证》，房地产中介无需特殊资质"
        },
        {
          text: "拍卖典当",
          value: 716,
          message: "该类目费率0.6%，入账周期T+1",
          qulication_message: "拍卖：《拍卖经营批准证书》 典当：《典当经营许可证》"
        },
        {
          text: "保险业务",
          value: 715,
          message: "该类目费率0.6%，入账周期T+1",
          qulication_message: "保险公司提供《经营保险业务许可证》《保险业务法人等级证书》，其他公司提供相关资质"
        },
        {
          text: "财经/股票类资讯",
          value: 713,
          message: "该类目费率0.6%，入账周期T+7，单笔限额3K",
          qulication_message: "若有具体的荐股行为，需资质《证券投资咨询业务资格证书》"
        }
      ]
    },
    {
      text: "民生缴费",
      children: [
        {
          text: "信用还款",
          value: 718,
          message: "该类目费率0.2%，入账周期T+1，禁信用卡",
          qulication_message: "1、银行：银监会颁发的《金融许可证》 2、消费金融：银监会颁发的《金融许可证》 3、互联网小额贷款企业：银监会颁发的互联网小额贷款资质证明 请根据企业类型提供以上三种证件中的一种，即三选一"
        },
        {
          text: "交通罚款",
          value: 716,
          message: "该类目费率0.6%，入账周期T+1",
          qulication_message: "收费授权证明文件（如授权证明书或合同）"
        },
        {
          text: "水电煤气缴费",
          value: 730,
          message: "该类目费率0.2%，入账周期T+1",
          qulication_message: "收费授权证明文件（如授权证明书或合同）"
        },
        {
          text: "其他生活缴费",
          value: 716,
          message: "该类目费率0.6%，入账周期T+1",
          qulication_message: "收费授权证明文件（如授权证明书或合同）"
        },
        {
          text: "有线电视缴费",
          value: 716,
          message: "该类目费率0.6%，入账周期T+1",
          qulication_message: "收费授权证明文件（如授权证明书或合同）"
        },
        {
          text: "话费通讯缴费",
          value: 728,
          message: "该类目费率0.6%，入账周期T+7，单笔限额3K",
          qulication_message: "提供与运营商间的合作授权收费协议"
        },
        {
          text: "电信运营商/宽带收费",
          value: 716,
          message: "该类目费率0.6%，入账周期T+1",
          qulication_message: "《电信业务经营许可证》"
        }
      ]
    },
    {
      text: "医疗",
      children: [
        {
          text: "挂号平台",
          value: 716,
          message: "该类目费率0.6%，入账周期T+1",
          qulication_message: "卫生局的批文"
        },
        {
          text: "民营医院/诊所/卫生站/卫生服务中心",
          value: 716,
          message: "该类目费率0.6%，入账周期T+1",
          qulication_message: "《医疗机构执业许可证》"
        },
        {
          text: "保健器械/医疗器械/非处方药品",
          value: 716,
          message: "该类目费率0.6%，入账周期T+1",
          qulication_message: "互联网售药：提供《互联网药品交易服务证》 线下门店卖药：提供《药品经营许可证》 医疗器械：《医疗器械经营企业许可证》"
        },
        {
          text: "宠物医院",
          value: 716,
          message: "该类目费率0.6%，入账周期T+1",
          qulication_message: "《动物诊疗许可证》"
        }
      ]
    },
    {
      text: "教育",
      children: [
        {
          text: "民办中小学及幼儿园",
          value: 717,
          message: "该类目费率0.3%，入账周期T+1",
          qulication_message: "民办非公立院校需提供《办学许可证》"
        },
        {
          text: "民办大学及院校",
          value: 739,
          message: "该类目费率0%，入账周期T+1",
          qulication_message: "民办非公立院校需提供《办学许可证》"
        },
        {
          text: "教育/培训/考试缴费/学费",
          value: 716,
          message: "该类目费率0.6%，入账周期T+1",
          qulication_message: "教育部门的批文或办学许可证"
        }
      ]
    }
  ],
  SUBJECT_TYPE_INSTITUTIONS: [
    {
      text: "民生缴费",
      children: [
        {
          text: "非税业务",
          value: 725,
          message: "该类目费率0.6%，入账周期T+1",
          qulication_message: "请提供非税证明材料，例如非税专户文件"
        },
        {
          text: "党团费",
          value: 725,
          message: "该类目费率0.6%，入账周期T+1",
          qulication_message: "请提供党费银行专户证明文件，党组织成立文件等"
        },
        {
          text: "交通罚款",
          value: 723,
          message: "该类目费率0.1%，入账周期T+1",
          qulication_message: "收费授权证明文件（如授权证明书或合同）"
        },
        {
          text: "水电煤气缴费",
          value: 725,
          message: "该类目费率0.6%，入账周期T+1",
          qulication_message: "收费授权证明文件（如授权证明书或合同）"
        },
        {
          text: "其他生活缴费",
          value: 725,
          message: "该类目费率0.6%，入账周期T+1",
          qulication_message: "收费授权证明文件（如授权证明书或合同）"
        }
      ]
    },
    {
      text: "医疗",
      children: [
        {
          text: "公立医院",
          value: 724,
          message: "该类目费率0%，入账周期T+1",
          qulication_message: "《医疗机构执业许可证》"
        },
        {
          text: "挂号平台",
          value: 724,
          message: "该类目费率0%，入账周期T+1",
          qulication_message: "卫生局的批文"
        }
      ]
    },
    {
      text: "公益/宗教",
      children: [
        {
          text: "公益",
          value: 725,
          message: "该类目费率0.6%，入账周期T+1",
          qulication_message: "《基金会法人登记证书》、法人资料业务范围有“接受捐款”相关字眼或有“慈善组织”标识"
        }
      ]
    }
  ],
  SUBJECT_TYPE_OTHERS: [
    {
      text: "交通/加油",
      children: [
        {
          text: "机票/机票代理",
          value: 727,
          message: "该类目费率0.6%，入账周期T+1",
          qulification_message: "《航空公司营业执照》或《航空公司机票代理资格证》"
          
        }
      ]
    },
    {
      text: "民生缴费",
      children: [
        {
          text: "其他生活费",
          value: 727,
          message: "该类目费率0.6%，入账周期T+1",
          qulification_message: "收费授权证明文件（如授权证明书或合同）"
        }
      ]
    },
    {
      text: "医疗",
      children: [
        {
          text: "民营医院/诊所/卫生站/卫生服务中心",
          value: 727,
          message: "该类目费率0.6%，入账周期T+1",
          qulification_message: "《医疗机构执业许可证》"
        }
      ]
    },
    {
      text: "教育",
      children: [
        {
          text: "民办中小学及幼儿园",
          value: 738,
          message: "该类目费率0.3%，入账周期T+1",
          qulication_message: "民办非公立院校需提供《办学许可证》"
        },
        {
          text: "民办大学及院校",
          value: 726,
          message: "该类目费率0%，入账周期T+1",
          qulication_message: "民办非公立院校需提供《办学许可证》"
        },
        {
          text: "教育/培训/考试缴费/学费",
          value: 727,
          message: "该类目费率0.6%，入账周期T+1",
          qulification_message: "教育部门的批文或办学许可证"
        }
      ]
    },
    {
      text: "公益/宗教",
      children: [
        {
          text: "宗教组织/寺庙",
          value: 727,
          message: "该类目费率0.6%，入账周期T+1",
          qulication_message: "宗教类提供《宗教活动场所登记证》"
        },
        {
          text: "公益",
          value: 726,
          message: "该类目费率0%，入账周期T+1",
          qulication_message: "《基金会法人登记证书》、法人资料业务范围有“接受捐款”相关字眼或有“慈善组织”标识"
        }
      ]
    }
  ]
}

export const ID_DOC_TYPE_MAP = {
  IDENTIFICATION_TYPE_IDCARD: "IDENTIFICATION_TYPE_IDCARD",
  IDENTIFICATION_TYPE_OVERSEA_PASSWORD: "IDENTIFICATION_TYPE_OVERSEA_PASSWORD",
  IDENTIFICATION_TYPE_HONGKONG_PASSPORT: "IDENTIFICATION_TYPE_HONGKONG_PASSPORT",
  IDENTIFICATION_TYPE_TAIWAN_PASSPORT: "IDENTIFICATION_TYPE_TAIWAN_PASSPORT"
};

export const ID_DOC_TYPE_LIST = [
  {
    text: "中国大陆居民-身份证",
    value: "IDENTIFICATION_TYPE_IDCARD"
  },
  {
    text: "其他国家或地区居民-护照",
    value: "IDENTIFICATION_TYPE_OVERSEA_PASSWORD"
  },
  {
    text: "中国香港居民-来往内地通行证",
    value: "IDENTIFICATION_TYPE_HONGKONG_PASSPORT"
  },
  {
    text: "中国澳门居民-来往内地通行证",
    value: "IDENTIFICATION_TYPE_MACAO_PASSPORT"
  },
  {
    text: "中国台湾居民-来往大陆通行证",
    value: "IDENTIFICATION_TYPE_TAIWAN_PASSPORT"
  }
]

export const BANK_ACCOUNT_TYPE_LIST = [
  {
    text: "经营者个人银行卡",
    value: "BANK_ACCOUNT_TYPE_PERSONAL"
  },
  {
    text: "对公银行账户",
    value: "BANK_ACCOUNT_TYPE_CORPORATE"
  }
]

export const BANK_LIST = [
  "工商银行",
  "交通银行",
  "招商银行",
  "民生银行",
  "中信银行",
  "浦发银行",
  "兴业银行",
  "光大银行",
  "广发银行",
  "平安银行",
  "北京银行",
  "华夏银行",
  "农业银行",
  "建设银行",
  "邮政储蓄银行",
  "中国银行",
  "宁波银行",
  "其他银行"
]