<template>
  <div class="page-wrapper">
    <div class="page-content">
      <div class="page-content-header">
        <div class="page-content-title">准备材料</div>
        <div class="page-content-description">申请前请准备以下材料</div>
      </div>
      <div class="page-content-body">
        <div class="step">
          <div class="step-title">
            1.{{ certificateTypeMap[$route.query.type].title }}
          </div>
          <div class="step-content">
            <div class="step-description">
              需年检章齐全，到期时间必须大于申请之时3个月
            </div>
            <div class="step-image">
              <img
                :src="
                  require(`@/assets/images/${
                    certificateTypeMap[$route.query.type].img
                  }`)
                "
              />
            </div>
          </div>
        </div>
        <div class="step">
          <div class="step-title">2.特殊资质</div>
          <div class="step-content">
            <div class="step-description">
              涉及到需要凡特殊资质的、都需要上传特殊资质证书
            </div>
            <div class="step-image">
              <img src="@/assets/images/certifications.png" />
            </div>
          </div>
        </div>
        <div class="step">
          <div class="step-title">3.法人证件</div>
          <div class="step-content">
            <div class="step-description">
              到期时间必须大于申请之时3个月
              <p>
                支持：中国大陆居民-身份证，中国香港居民-来往内地通行证，中国澳门居民-来往内地通行证，中国台湾居民-来往大陆通行证，其他国家或地区-护照
              </p>
            </div>
            <div class="step-image">
              <img src="@/assets/images/id card.png" />
            </div>
          </div>
        </div>
        <div class="step">
          <div class="step-title">4.法人银行卡</div>
          <div class="step-content">
            <div class="step-description">
              特殊类目需提供相应的资质文件，例如餐饮类目需提供《餐饮服务许可证》或实体店铺的三张照片。
            </div>
          </div>
        </div>
      </div>
      <div class="page-content-footer">
        <button class="success-btn" @click="handleClick">我知道了</button>
      </div>
    </div>
  </div>
</template>
<script>
import { SUBJECT_TYPE_LIST } from "../apply/config";
export default {
  data() {
    return {
      certificateTypeMap: {
        [`${SUBJECT_TYPE_LIST.SUBJECT_TYPE_INDIVIDUAL}`]: {
          title: "营业执照",
          img: "business license.png",
        },
        [`${SUBJECT_TYPE_LIST.SUBJECT_TYPE_ENTERPRISE}`]: {
          title: "营业执照",
          img: "business license.png",
        },
        [`${SUBJECT_TYPE_LIST.SUBJECT_TYPE_INSTITUTIONS}`]: {
          title: "事业单位法人证书",
          img: "Institutions license.png",
        },
        [`${SUBJECT_TYPE_LIST.SUBJECT_TYPE_OTHERS}`]: {
          title: "民办非企业法人证书",
          img: "certificate.png",
        },
      },
    };
  },
  methods: {
    handleClick() {
      this.$router.push({
        name: "ApplyAdd",
        params: {
          type: this.$route.query.type,
        },
      });
    },
  }
};
</script>
<style lang="less" scoped>
.page-wrapper {
  background: #5aa16c;
  padding: 16px 10px;

  .page-content {
    background: #ffffff;
    border-radius: 5px;
    padding: 30px 20px;

    .page-content-header {
      text-align: center;
      border-bottom: 1px solid rgba(144, 143, 143, 0.5);
      padding-bottom: 16px;

      .page-content-title {
        font-size: 18px;
        line-height: 25px;
        color: #0d0c0c;
      }

      .page-content-description {
        margin-top: 5px;
        font-size: 12px;
        color: #0d0c0c;
      }
    }

    .page-content-body {
      margin-top: 16px;

      .step {
        &:not(:first-child) {
          margin-top: 25px;
        }

        .step-title {
          font-size: 16px;
          color: #0d0c0c;
        }

        .step-content {
          padding: 0 10px;
          .step-description {
            font-size: 12px;
            color: rgba(13, 12, 12, 0.4);

            p {
              color: rgba(13, 12, 12, 0.8);
              margin: 0;
            }
          }
          .step-image {
            margin-top: 11px;

            img {
              max-width: 100%;
            }
          }
        }
      }
    }

    .page-content-footer {
      margin-top: 30px;
      text-align: center;

      .success-btn {
        width: 180px;
        height: 40px;
        border: 1px solid #5aa16c;
        color: #5aa16c;
        font-size: 15px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        background: #ffffff;
      }
    }
  }
}
</style>